.App {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(242, 140, 85);
  padding: 0 1rem;
}

.App-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 1rem;
  max-width: 1276px;
  margin: 0 auto;
}

.App-navbar__right {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.box {
  margin: 0;
  padding: 0.5rem;
  /* background-color: rgb(91 100 102 / 75%); */
}

h1.supply {
  font-family: "Square Pixel";
  color: rgb(0 0 0 / 30%);
  margin: 0;
  font-weight: normal;
}

.mint {
  font-size: 2rem !important;
}

.error-message {
  font-size: 1rem;
  color: #f01d6c;
  background: black;
  padding: 1rem;
  border-radius: 4px;
  word-wrap: break-word;
}

.button {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  padding: 0.5rem 4rem;
  border: 5px solid #000;
  border-radius: 5px;
  background-color: #d97947;
  color: #000;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  text-transform: lowercase;
  font-family: "Pixel Dead", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.button.inactive {
  background-color: #834d30;
}

.button-floater {
  position: relative;
  font-family: "Square Pixel";
  color: rgb(0 0 0 / 30%);
}

.button:hover {
  background-color: #e36f32;
}

.button.mints {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button.mints .title {
  font-size: 2rem;
}

button:disabled {
  background-color: #625d5a;
  cursor: not-allowed;
}

button:disabled:hover {
  background-color: #2f2a34;
}

.button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.limited-edition {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.question-mark {
  font-size: 4rem !important;
  color: #f01d6c;
  margin: 0;
}

.App-navbar h1 {
  font-size: 2rem;
}

.App-link {
  color: #61dafb;
}

.App-main {
  display: grid;
  width: 100%;
  max-width: 1500px;
  height: 100vh;
}

@media (max-width: 1276px) {
  .App {
    height: 100% !important;
  }
  .App-main {
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .App-navbar {
    justify-content: center !important;
  }
  .grid {
    grid-template-columns: 1fr !important;
    grid-template-rows: auto !important;
  }
  .grid-item {
    justify-content: center !important;
  }
  .grid-item.menu {
    padding-top: 0 !important;
  }
  .grid-item img {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(2, 1fr); */
  grid-gap: 3rem;
  grid-auto-rows: auto;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.grid-item.flex-end {
  justify-content: flex-end;
}

.grid-item.menu {
  align-items: center;
  padding-top: 5rem;
}

.grid-item.logo {
  align-items: center;
  justify-content: center;
}

.grid-item img {
  max-width: 150%;
  width: 150%;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
}

.grid-item.middle-image img {
  display: flex;
  flex-direction: column;
  width: 33% !important;
}

.middle-image-text {
  display: flex;
  flex-direction: column;
  color: #f01d6c;
  font-size: 2rem;
  font-weight: bold;
}

.middle-image_text_below {
  color: white;
}

/* .modal { */
/*   position: fixed; */
/*   display: flex; */
/*   justify-content: center; */
/*   top: 30%; */
/*   background-color: #2f2a34; */
/*   color: #fff; */
/*   padding: 3rem !important; */
/*   z-index: 20; */
/*   border-radius: 1rem; */
/* } */
/**/
/* .modal-content { */
/*   display: flex; */
/*   flex-direction: column; */
/*   gap: 1rem; */
/* } */

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: block;
  overflow: hidden;
  outline: 0;
  background: black;
  opacity: 0.5;
}

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
}

.modal-header {
  padding-top: 3rem;
}

/* Modal Content */
.modal-content {
  margin: auto;
  width: 300px;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.modal-body {
  display: grid;
  grid-template-columns: auto auto auto auto;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
}

.thumbnail {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
}

img.selected {
  border: 4px solid rgb(255 224 0) !important;
}

.thumbnail img {
  border: 4px solid rgb(255 255 0 / 15%);
  border-radius: 1rem;
}

/* The Close Button */
.close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: #ff7d1e;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  opacity: 0.5;
  text-decoration: none;
  cursor: pointer;
}

.counter {
  padding: 1rem 0;
  font-size: 3rem;
  font-family: "Square Pixel", sans-serif;
}

.counter.overboard {
  color: red;
}

.error {
  color: red;
  font-size: 1rem;
  padding: 1rem;
}

.input-number {
  display: flex;
}

.input-number__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  background: transparent;
  border: 3px solid rgb(0 0 0 / 30%);
}

.input-number__button:hover {
  opacity: 90%;
}

.input-number__input {
  background-color: transparent;
  width: 100%;
  font-family: "Square Pixel";
  font-size: 2rem;
  text-align: center;
  border: 3px solid rgb(0 0 0 / 30%);
  border-radius: 5px;
}

.mintButton {
  display: flex;
  gap: 0.5rem;
}

.mintButton .mints {
  width: 150%;
}
